#header-propuesta {
  padding: 24px 32px;
}

#regresar-propuesta {
  display: flex;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  text-decoration-line: underline;
  color: #0f69b4;
  opacity: 0.5;
}

#regresar-propuesta p {
  margin-bottom: 0;
}

#regresar-propuesta svg {
  height: 16px;
}

#div-header-titulo-botones-propuesta {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#titulo-propuesta {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #0f69b4;
  margin-bottom: 0;
}

#div-header-botones-propuesta {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.botones-header-propuesta {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 4px;
  border-radius: 5px;
  border: none;
}

#boton-abrir-chat-propuesta {
  background: #dbeaf7;
  color: #0f69b4;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
}

#dropdown-estatus-propuesta {
  background: #ffffff;
  color: #000000;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  min-width: 192px;
  justify-content: space-between;
}

#dropdown-estatus-propuesta::after {
  display: none;
}

.item-dropdown-estatus-propuesta {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
}

#boton-redireccionar-propuesta,
#boton-escalar-propuesta {
  background: #0f69b4;
  color: #ffffff;
}

.botones-header-propuesta p {
  margin-bottom: 0;
}

#datos-propuesta {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 32px;
  margin-bottom: 16px;
}

.bolita-estatus {
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  border-radius: 8px;
  margin-right: 10px;
}

.bolita-estatus-azul {
  background: #1387da;
  border: 1px solid #f9fafc;
}

.bolita-estatus-azul-claro {
  background: #85ccff;
  border: 1px solid #f9fafc;
}

.bolita-estatus-verde {
  background: #3bd278;
  border: 1px solid #ffffff;
}

.bolita-estatus-amarillo {
  background: #f7c137;
  border: 1px solid #ffffff;
}

.bolita-estatus-naranja {
  background: #ff780b;
  border: 1px solid #ffffff;
}

.bolita-estatus-rojo {
  background: #f42449;
  border: 1px solid #ffffff;
}

.bolita-estatus-negro {
  background: #49505d;
  border: 1px solid #f4f6fc;
}

.bolita-estatus-gris {
  background: #d5deff;
  border: 1px solid #f4f6fc;
}

#form-propuesta {
  background: #ffffff;
  padding: 24px;
  width: 100%;
}

.labels-propuesta {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #0f69b4;
}

.inputs-propuesta,
#div-votos {
  background: #ffffff;
  border: 1px solid #1387da;
  box-sizing: border-box;
  border-radius: 5px;
  color: #4d4d4d;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  height: 40px;
}

.inputs-propuesta[readonly] {
  background: #ffffff;
}

#div-votos {
  display: flex;
  justify-content: center;
  align-items: center;
}

#contenedor-de-quill-propuesta {
  background: #ffffff;
  border: 1px solid #1387da;
  box-sizing: border-box;
  border-radius: 5px;
  min-height: 250px;
  padding: 24px 16px 16px 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #49505d;
}

#div-etiquetas {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.etiquetas-propuesta {
  background: #dbeaf7;
  border-radius: 14px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #325897;
  height: 28px;
  width: 81px;
  margin: 6px;
}

#propuesta-boton-descargar {
  background: #dbeaf7;
  border: 1px solid #1387da;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  color: #0f69b4;
  height: 40px;
}

#propuesta-boton-descargar p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 0;
}

.secciones-propuesta {
  margin-bottom: 34px;
}

#comentarios-actividad-propuesta {
  padding: 0 32px;
}

#div-tabs-propuesta {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.tab-activo-propuesta {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 48px;
  border-radius: 2px;
  background: #ffffff;
  color: #0f69b4;
  pointer-events: none;
  touch-action: none;
}

.tabs-inactivos-propuesta {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 48px;
  border-radius: 2px;
  color: #a6acb3;
  background: #f9fafc;
}

.tab-activo-propuesta svg,
.tabs-inactivos-propuesta svg {
  pointer-events: none;
  touch-action: none;
}

.tab-activo-propuesta p,
.tabs-inactivos-propuesta p {
  margin-bottom: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  pointer-events: none;
  touch-action: none;
}

#form-comentarios-propuesta {
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 0 16px;
  padding-top: 0;
}

#input-comentario-propuesta {
  height: 40px;
  background: #f9fafc;
  border: 1px solid #1387da;
  box-sizing: border-box;
  border-radius: 5px;
  margin-right: 24px;
}

#boton-comentar-propuesta {
  height: 40px;
  background: #1387da;
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
}

#boton-comentar-propuesta p {
  margin-bottom: 0;
}

#contenido-comentarios-actividad-propuesta {
  min-height: 243px;
  background: #ffffff;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 32px;
}

#div-render-comentarios-propuesta {
  height: calc(100% - 80px);
  width: 100%;
  display: flex;
  flex-flow: column;
  max-height: 500px;
  overflow-y: scroll;
}

.comentario-contenedor-primario-propuesta,
.comentario-contenedor-secundario-propuesta,
.comentario-contenedor-terciario-propuesta,
.comentario-contenedor-cuaternario-propuesta {
  display: flex;
}

.comentario-contenedor-primario-propuesta {
  background: #f9fafc;
  border-radius: 2px;
  margin: 16px;
  align-items: flex-start;
  padding: 16px;
}

.comentario-contenedor-primario-propuesta img {
  width: 40px;
  margin-right: 16px;
}

.comentario-contenedor-secundario-propuesta {
  flex-flow: column;
  width: 100%;
}

.comentario-contenedor-terciario-propuesta {
  justify-content: space-between;
  width: 100%;
}

.comentario-contenedor-cuaternario-propuesta {
  justify-content: flex-start;
}

.nombres-comentarios-propuesta {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #0f69b4;
  margin-right: 7px;
  margin-bottom: 8px;
}

.fecha-comentarios-propuesta {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #7f8792;
  margin-bottom: 8px;
}

.dropdown-comentario-propuesta,
.dropdown-comentario-propuesta:hover,
.dropdown-comentario-propuesta:focus,
.dropdown-comentario-propuesta:active,
.dropdown-comentario-propuesta:not(:disabled):not(.disabled):active {
  background: none;
  color: #0f69b4;
  height: 30px;
  padding: 0;
  border: none;
  box-shadow: none;
}

.dropdown-comentario-propuesta:hover {
  background: none;
  color: #0f69b4;
}

.dropdown-comentario-propuesta::after {
  display: none;
}

.texto-comentarios-propuesta {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 180%;
  color: #000000;
}

#contenedor-modal-escalar-propuesta {
  background: #f9fafc;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  min-width: 472px;
  min-width: 680px;
}

#seccion-datos-escalar-propuesta {
  padding: 32px 32px 0 32px;
}

#titulo-escalar-propuesta {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #0f69b4;
  margin-bottom: 33px;
}

.labels-escalar-propuesta {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #0f69b4;
  margin-bottom: 0;
}

#dropdown-escalar-propuesta {
  background: #ffffff;
  border: 1px solid #1387da;
  box-sizing: border-box;
  border-radius: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #7f8792;
  width: 340px;
  height: 40px;
  margin-bottom: 23px;
}

#dropdown-escalar-propuesta div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#dropdown-escalar-propuesta div p {
  margin-bottom: 0;
}

#dropdown-escalar-propuesta div svg {
  color: #0f69b4;
  width: 15px;
}

#dropdown-escalar-propuesta::after {
  display: none;
}

#comentarios-escalar-propuesta {
  background: #ffffff;
  border: 1px solid #1387da;
  box-sizing: border-box;
  border-radius: 5px;
}

#seccion-botones-escalar-propuesta {
  height: 84px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 24px;
}

#boton-cancelar-escalar-propuesta,
#boton-modal-escalar-propuesta {
  width: 107px;
  height: 36px;
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.03em;
  justify-content: center;
  text-align: center;
  border: none;
}

#boton-cancelar-escalar-propuesta {
  background: #dbeaf7;
  color: #0f69b4;
  margin-right: 16px;
}

#boton-modal-escalar-propuesta {
  background: #1387da;
  color: #ffffff;
}

#seccion-quill-propuesta .ql-toolbar {
  display: none;
}

.div-comentarios-o-actividad-vacio {
  width: 100%;
  height: 100%;
  min-height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.div-banner-comentario-rojo {
  background: rgba(227, 54, 62, 0.2);
  border-radius: 2px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #4a4a4a;
}

.div-banner-comentario-rojo p {
  margin-bottom: 0;
}

.div-banner-comentario-rojo svg {
  color: #e3363e;
  min-width: 20px;
  margin: 0 12px;
}

.etiqueta-respuestas {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #7f8792;
  margin-bottom: 0;
}

.barra-gris-2X32 {
  background: #eeeeee;
  height: 32px;
  width: 2px;
}

.div-votos-comentarios-propuestas {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.div-votos-comentarios-propuestas p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #a6acb3;
  margin: 0 14px;
}

.div-votos-comentarios-propuestas svg {
  color: #a6acb3;
  width: 16px;
}

.div-votos-comentarios-propuestas svg:hover,
.div-votos-comentarios-propuestas p:hover {
  color: #0f69b4;
}

p.texto-negro-votos-comentarios-propuestas {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #49505d;
}

.img-avatar-comentarios-movil {
  display: none;
}

.boton-cerrar-preview-accion {
  background: rgb(240, 243, 255);
  color: rgb(36, 117, 185);
  border: none;
  width: 130px;
  margin: 10px;
  border-radius: 5px;
  float: right;
  height: 40px;
}

.input-junta-vecinal {
  text-transform: capitalize;
}

@media screen and (max-width: 600px) {
  #div-header-botones-propuesta {
    width: 100%;
  }
  #div-header-titulo-botones-propuesta {
    flex-flow: column;
  }
  #header-propuesta {
    padding: 16px;
  }
  #boton-escalar-propuesta {
    position: absolute;
    top: 100px;
    position: absolute;
    top: 100px;
    width: 36px;
    height: 36px;
    padding: 0;
    border-radius: 18px;
  }
  #boton-escalar-propuesta p {
    display: none;
  }
  #dropdown-estatus-propuesta {
    min-width: 49%;
  }

  #titulo-propuesta {
    padding: 0 78px 16px 0;
    width: 100%;
    text-align: left;
  }
  #datos-propuesta {
    padding: 0 16px;
  }
  #form-propuesta {
    padding: 16px;
  }
  #propuesta-boton-descargar {
    width: 100%;
  }
  #comentarios-actividad-propuesta {
    padding: 0 16px;
  }

  .comentario-contenedor-terciario-propuesta {
    justify-content: flex-start;
    width: 100%;
  }
  .comentario-contenedor-cuaternario-propuesta {
    flex-flow: column;
    width: -webkit-fill-available;
  }

  .img-avatar-comentarios-desktop {
    display: none;
  }

  .img-avatar-comentarios-movil {
    display: block;
  }

  #boton-comentar-propuesta {
    min-width: 40px;
    height: 40px;
    padding: 0;
    justify-content: center;
    border-radius: 20px;
  }

  #boton-comentar-propuesta p {
    display: none;
  }

  #contenido-comentarios-actividad-propuesta {
    flex-flow: column-reverse;
  }

  #contenedor-modal-escalar-propuesta {
    min-width: 90%;
    max-width: 90vh;
  }

  .card-preview {
    width: 95%;
  }

  .comentario-contenedor-primario-propuesta p {
    font-size: 12px;
  }

  .comentario-contenedor-primario-propuesta img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  .comentario-contenedor-primario-propuesta {
    margin: 16px 0;

}
}
