.modal {
  width: 100%;
}
.modal-publicaciones {
  background-color: #f5f5f5;
  width: 80%;
}
.headers-row {
  border: solid #dee2e9 1px;
}
.header-text {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  padding: 2px;
  margin: 0;
  font-size: 115%;
  color:#0f69b4;

}
.data-text {
    text-align: center;
    padding: 2px;
    margin: 0;
  }
.columnas {
  border: solid #dee2e9 1px;
}
.table-modal {
background-color: #fff;
  border-collapse: collapse;
  align-items: center;
  padding: 25px;
  margin:8px;
}
h4{
    color:#0f69b4;
    margin: 10px;
    text-align: center;
}
