#contenedor-modal-nueva-encuesta {
  background: #f9fafc;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  width: 680px;
  min-height: fit-content;
}

#contenedor-modal-nueva-encuesta:focus {
  outline: none;
}

#seccion-datos-nueva-encuesta {
  padding: 32px;
}

#titulo-nueva-encuesta {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #0f69b4;
  margin-bottom: 0;
}

.labels-crear-nueva-encuesta {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #0f69b4;
  margin-top: 32px;
  margin-bottom: 0;
}

#input-nombre-de-encuesta {
  background: #ffffff;
  border: 1px solid #1387da;
  box-sizing: border-box;
  border-radius: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  height: 40px;
}

#fecha-de-terminacion-crear-encuesta {
  /* max-width: 340px; */
}

.dropdown-crear-encuesta {
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #1387da;
  box-sizing: border-box;
  border-radius: 5px;
}

.dropdown-crear-encuesta:focus,
.dropdown-crear-encuesta:active {
  background: #ffffff !important;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5) !important;
}

.dropdown-crear-encuesta:hover {
  background: #ffffff !important;
}

.dropdown-crear-encuesta p {
  margin-bottom: 0;
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.dropdown-crear-encuesta svg {
  color: #0f69b4;
}

.dropdown-crear-encuesta::after {
  display: none;
}

#seccion-botones-nueva-encuesta {
  display: flex;
  justify-content: flex-end;
  padding: 0 24px 24px 24px;
}

#seccion-botones-nueva-encuesta button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 107px;
  height: 36px;
  border-radius: 5px;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.03em;
}

#seccion-botones-nueva-encuesta button:focus {
  outline: none;
}

#boton-guardar-crear-encuesta {
  background: #1387da;
  border-radius: 5px;
  margin-left: 16px;
  color: #ffffff;
}

#boton-cancelar-crear-encuesta {
  background: #dbeaf7;
  color: #0f69b4;
}

.accion-vinculada-tablero-votaciones {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.accion-vinculada-tablero-votaciones p {
  margin-bottom: 0;
}

.accion-vinculada-tablero-votaciones svg {
  color: #0f69b4;
  width: 13px;
}

#barrio-crear-encuesta {
  width: 100%;
}

#barrio-crear-encuesta .dropdown {
  width: 100%;
}

#boton-subir-imagen-encuesta {
  background: #d7ebfb;
  color: #0f69b4;
  border-radius: 5px;
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
}

#boton-subir-imagen-encuesta p {
  margin-bottom: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
}

#boton-subir-imagen-encuesta svg {
  width: 12px;
}

#estatus-crear-encuesta .dropdown {
  width: 100%;
}

#subir-imagen-nueva-encuesta {
  /* display: none; */
}

#titulo-estatus-nueva-encuesta {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* --------------- Responsive ---------------- */

@media screen and (max-width: 680px) {
  #contenedor-modal-nueva-encuesta {
    width: 90%;
    max-height: 100%;
    overflow-y: auto;
  }

  #contenedor-modal-nueva-encuesta .row{
    flex-flow: column;
  }
}