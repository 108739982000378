.container-form{
    margin: 1em
}

.rowForm{
    margin-bottom: 2.5em;
}


.tabsTable{
    margin: 1.5em 0em
}
