.icon{
    height: 2em;
    width: 100% !important;
    color: #2167a0
}

.load-more-container {
    padding-top: 2em;
    padding-bottom: 2em;
}

.load-more {
    text-align: center;
    width: 50%;
    margin: auto;
    word-wrap: break-word;
    color: #0F69B4;
}

.load-more:hover {
    cursor: pointer;
    color: #1387DA;
}
