.carousel {
  height: 50vh;
}
.carousel-inner {
  height: inherit;
}
.img-carousel {
  height: inherit;
}

.carousel-caption {
  right: 5%;
  left: 5%;
  text-align: left;
  z-index: 2;
}

.div-titulo-proyecto-tendencia {
  display: flex;
  align-items: center;
}

.bg-cover-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.imgContainer-card {
  height: 20rem;
}

.imgContainer-card img {
  height: inherit;
}

.carousel-gradient {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,001c41+50,001c41+100&0+0,0+0,0.65+100 */
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(1, 28, 65, 0.75) 20%,
    rgba(0, 28, 65, 0.65) 80%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(1, 28, 65, 0.75) 20%,
    rgba(0, 28, 65, 0.65) 80%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(1, 28, 65, 0.75) 20%,
    rgba(1, 28, 65, 0.8) 85%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6001c41',GradientType=0 ); /* IE6-9 */
  right: 0%;
  left: 0%;
  bottom: 0%;
  padding: 16px;
}

.cards-text {
  margin-top: 1em;
  margin-bottom: 1.5em;
  line-height: 2em;
}

.cards-title {
  margin-top: 1.5em;
  margin-bottom: 1em;
}

.badge-component-style {
  background: #d7ebfb;
  border-radius: 14px;
  color: #2475b9;
  padding: 8px 10px;
  margin-right: 1%;
  margin-bottom: 2%;
}
