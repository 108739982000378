.botones-generar-reportes {
  background: #d7ebfb;
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #0f69b4;
  border: none;
  height: 25px;
}

.botones-generar-reportes:focus {
  outline: none;
}

.div-modal-generar-reporte {
  background: #f9fafc;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  border: none;
  height: 200px;
  width: 320px;
  padding: 10px;
  display: flex;
  flex-flow: column;
}

.div-modal-generar-reporte input {
  background: #ffffff;
  border: 1px solid #1387da;
  box-sizing: border-box;
  border-radius: 3px;
}

.div-modal-generar-reporte input:focus {
  outline: none;
}

.div-modal-generar-reporte label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #0f69b4;
  margin-top: 10px;
}

.div-modal-generar-reporte:focus {
  outline: none;
}

.div-botones-modal-generar-reporte {
  margin-top: 10px;
  display: flex;
  justify-content: space-evenly;
}

.div-botones-modal-generar-reporte button {
  border-radius: 5px;
  border: none;
  height: 36px;
  width: 110px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.03em;
}

/* DetalleReportes */
.contenedor-detalles-reporte {
  margin-top: 72px;
  padding: 0 30px;
}

.contenedor-detalles-reporte .col {
  padding: 0;
}

.row-filtros-reporte {
  padding: 10px;
  background-color: #f5f5f5;
  display: flex;
  flex-wrap: wrap;
}

.opciones-filtros-reportes {
  display: flex;
  align-items: center;
  min-width: fit-content;
  margin: 5px 0;
}

.opciones-filtros-reportes p {
  text-transform: uppercase;
}

.opciones-filtros-reportes input {
  background: #ffffff;
  border: 1px solid #1387da;
  box-sizing: border-box;
  border-radius: 3px;
}

.div-input-multidropdown-comunas-destino,
.div-input-multidropdown-comunas-origen {
  padding-right: 10px;
}

.div-input-multidropdown-comunas-destino,
.div-input-multidropdown-comunas-origen,
.div-input-multidropdown-sectores,
.div-input-multidropdown-usarios {
  width: 100%;
}

#input-multidropdown-comunas-destino,
#input-multidropdown-comunas-origen,
#input-multidropdown-sectores,
#input-multidropdown-usuarios {
  width: -webkit-fill-available;
  margin: 0;
}

.opciones-filtros-reportes input:focus {
  outline: none;
}

.opciones-filtros-reportes ul {
  list-style: none;
  background: #feffff;
  box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  padding: 5px;
  position: absolute;
  z-index: 2;
  max-height: 30vh;
  overflow-y: scroll;
}

.opciones-filtros-reportes li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 2px 0;
}

.opciones-filtros-reportes p,
.opciones-filtros-reportes label,
.opciones-filtros-reportes input,
.opciones-filtros-reportes option,
.opciones-filtros-reportes select,
.opciones-filtros-reportes li {
  margin-right: 10px;
  margin-bottom: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.1em;
  color: #000000;
  white-space: nowrap;
}

.inputs-edad-reporte {
  width: 50px;
}

path.surface,
text.slicetext,
.point path {
  transition: all 0.3s;
}

.boton-descragar-excel-reporte {
  width: 100px;
  height: 36px;
  background: #d7ebfb;
  border-radius: 5px;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.03em;
  color: #0f69b4;
}

.boton-descragar-excel-reporte:focus {
  outline: none;
}

/* Generador de graficos */

.js-plotly-plot .plotly .modebar.vertical {
  width: 30px;
}
