html {
  background-color: #fbfbff;
}
.App {
  text-align: center;
  background-color: #fbfbff;
  height: 100%;
}
.drawer-content {
  min-height: 100vh;
  padding-left: 240px;
  width: 100%;
  height: 100%;
  background-color: #fbfbff;
}

.section {
  padding-top: 72px;
  width: 100% !important;
  height: 100% !important;
  overflow-x: hidden;
  background-color: inherit;
}

.table {
  background-color: white;
}

.breadcrumb-section {
  margin: 1.5em 0em;
}

.tag {
  background-color: rgb(176, 231, 255);
  display: inline-flex;
  border-radius: 40px 40px 40px 40px;
  padding: 0.6em 1.2em;
  margin-right: 1em;
  width: fit-content;
}

.container-information {
  margin-right: 3em !important;
  margin-left: 2.5em !important;
}

.nav-tabs .nav-item {
  width: fit-content !important;
  padding: 0 10px;
}

.nav-tabs span {
  color: #a6acb3;
}

.nav-tabs .active span {
  color: #0f69b4;
}

.iconTab {
  margin-right: 1em;
}

.button-with-icon {
  display: inline-flex;
}
.button-with-icon p {
  margin: 0;
}
.button-with-icon svg {
  margin-right: 0.5em;
}

.cuerpo-modal-borrado {
  width: 520px;
  height: 240px;
  background: #f4f6fc;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  padding: 24px;
}

.titulo-modal-borrado {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #e3363e;
  margin-bottom: 32px;
}

.detalle-modal-borrado {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  min-height: 88px;
  margin-bottom: 0;
}

.div-botones-modal-borrado {
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
}

.boton-cancelar-modal-borrado,
.boton-eliminar-modal-borrado {
  width: 107px;
  height: 36px;
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.03em;
  justify-content: center;
  border: none;
}

.boton-cancelar-modal-borrado:focus,
.boton-eliminar-modal-borrado:focus {
  outline: none;
}

.boton-cancelar-modal-borrado {
  background: #f9fafc;
  color: #000000;
}

.boton-eliminar-modal-borrado {
  background: #e3363e;
  color: #ffffff;
  margin-left: 24px;
}

.banner-azul-mensajes-cambios,
.banner-rojo-mensajes-cambios {
  width: 100%;
  min-height: 40px;
  box-sizing: border-box;
  border-radius: 2px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  padding: 7px 0;
}

.banner-azul-mensajes-cambios {
  background: #d7ebfb;
  border: 1px solid #d7ebfb;
  color: #0f69b4;
}

.banner-rojo-mensajes-cambios {
  background: rgba(227, 54, 62, 0.2);
  border: 1px solid rgba(227, 54, 62, 0.2);
  color: #e3363e;
}

.banner-azul-mensajes-cambios svg,
.banner-rojo-mensajes-cambios svg {
  width: 16px;
  margin: 0 12px;
}

.banner-azul-mensajes-cambios .banner-x-cerrar,
.banner-rojo-mensajes-cambios .banner-x-cerrar {
  cursor: pointer;
}

.banner-azul-mensajes-cambios p,
.banner-rojo-mensajes-cambios p {
  margin-bottom: 0;
  width: 100%;
}

@media (max-width: 700px) {
  .container-information {
    margin-right: 0em !important;
    margin-left: 0em !important;
  }
  .nav-tabs .nav-item {
    width: 50%;
  }
}

.titulo-table-actions {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #0f69b4;
}

.nav-tabs a {
  display: flex;
  height: 40px;
  align-items: center;
}

@media (max-width: 960px) {
  .drawer-content {
    padding-left: 0 !important;
    margin-left: 0;
    background-color: #fbfbff;
  }
}
/* Preview Styles */

.card-preview {
  width: 70%;
  padding: 1.25rem;
  height: 95%;
  overflow: auto;
}

.card-title-preview {
  font-size: 1.25rem;
  margin-bottom: 14px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #0f69b4;
  height: 44px;
}

.card-tags-date-social {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
}

.card-tags-date-social .col {
  min-width: fit-content;
}

.card-preview-fecha {
  font-size: 14px;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.social-media-div {
  margin: 0;
  width: fit-content;
}

.social-media-icons {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.social-media-icons p {
  font-size: 14px;
}

.social-media-icons svg {
  width: 16px;
  height: auto;
}

.icon-social-media-container {
  background-color: #f9fafc;
  /* margin: 1em; */
  padding: 0.5em;
  border-radius: 50%;
  color: #7f8792;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 8px;
}

.social-media-card-text {
  width: fit-content;
}

.card-text-style {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #49505d;
  margin-bottom: 16px;
  min-height: 68px;
}

.card-info-image-novotes {
  width: 100%;
  height: -moz-fit-content;
  margin-right: 2%;
  align-self: center;
  display: flex;
  align-items: center;
  height: 350px;
  overflow: hidden;
  justify-content: center;
  background-size: cover;
}

.div-imagen-preview {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  backdrop-filter: blur(5px) brightness(75%);
}

.card-img-preview {
  height: 100%;
}

.card-info-votes {
  margin-top: 1.2em;
  width: 100%;
  align-self: center;
}

.progress-bar-container {
  margin-bottom: 0;
}

.progress {
  background: #abe9c3;
}

.progress-bar {
  background-color: #3bd278 !important;
}

.badge-component-style {
  background: rgb(240, 243, 255);
  color: rgb(36, 117, 185);
  border-radius: 14px;

  padding: 8px 10px;
  margin-right: 1%;
  margin-bottom: 2%;
}

#texto-descriptivo-tarjeta-proyecto-tendencias {
  overflow-y: auto;
  max-height: 180px;
}

.button-vote,
.button-vote:hover,
.button-vote:visited {
  background: #2475b9;
  border-radius: 5px;
  width: 100%;
  outline: none;
  border-color: #2475b9;
  box-shadow: none;
}

.quill-no-toolbar .ql-toolbar {
  display: none;
}

.quill-no-toolbar .ql-container.ql-snow {
  border: none;
}

.quill .ql-container {
  min-height: 250px;
}

.quill-no-toolbar .ql-container {
  min-height: fit-content;
}

.mensaje-respondiendo-comentario {
  align-self: start;
  margin-left: 20px;
  background: #d7ebfb;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
  padding: 5px 15px;
  width: fit-content;
  display: flex;
  align-items: center;
  color: #2475b9;
}

.mensaje-respondiendo-comentario p {
  margin-bottom: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
}

.mensaje-respondiendo-comentario svg {
  width: 20px;
  margin: 0 0 0 5px;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .mensaje-respondiendo-comentario {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    margin-top: 10px;
  }

  .mensaje-respondiendo-comentario p {
    font-size: 9px;
  }
}
