.container-comment{
    margin-top: 1em;
}

.profileImg-container-comment{
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    float: right;
    margin: 0;
}

.img-comment{
    width: inherit;
    height: inherit;
    border-radius: inherit;
}

.container-information-comment{
    display: inline-flex;
}

.name-comment{
    margin-right: 1em;
    margin-bottom: 0;
}

.date-comment{
    margin-bottom: 0;
}

.information-comment{

}