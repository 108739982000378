.dropdown-proyecto-detalle-encuesta {
  background: #f9fafc;
  border: 1px solid #1387da;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  width: 100%;
}

.dropdown-proyecto-detalle-encuesta:focus {
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

.dropdown-proyecto-detalle-encuesta:hover,
.dropdown-proyecto-detalle-encuesta:active,
.dropdown-proyecto-detalle-encuesta:focus {
  background: #f9fafc;
  border: 1px solid #1387da;
  box-sizing: border-box;
  outline: none;
}

.dropdown-proyecto-detalle-encuesta::after {
  display: none;
}

.dropdown-proyecto-detalle-encuesta p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #49505d;
  margin-bottom: 0;
}

.dropdown-proyecto-detalle-encuesta svg {
  color: #0f69b4;
}

.div-etiquetas-detalle-accion {
  background: #f9fafc;
  border: 1px solid #1387da;
  box-sizing: border-box;
  border-radius: 5px;
  height: 40px;
}

.boton-enviar-accion-detalle {
  background: #0f69b4;
  border-radius: 5px;
  height: 40px;
  width: 124px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #feffff;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.boton-enviar-accion-detalle p {
  margin-bottom: 0;
}

#subir-imagen-accion {
  display: none;
}

#input-comentario-accion {
  height: 40px;
  background: #f9fafc;
  border: 1px solid #1387da;
  box-sizing: border-box;
  border-radius: 5px;
  margin-right: 24px;
}

.secciones-propuesta .ql-container.ql-snow,
.secciones-propuesta .ql-toolbar.ql-snow {
  border: 1px solid #1387da;
  background: #f9fafc;
}

.secciones-propuesta .ql-container.ql-snow {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.secciones-propuesta .ql-toolbar.ql-snow {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

#div-votacion-ligada-accion {
  background: #0f69b4;
  border-radius: 5px;
  height: 40px;
  width: 100%;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

#div-votacion-ligada-accion a {
  margin: 0;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

#div-votacion-ligada-accion svg {
  margin-left: 10px;
}

.boton-cerrar-preview-propuesta {
  background: rgb(240, 243, 255);
  color: rgb(36, 117, 185);
  border: none;
  width: 130px;
  margin: 10px;
  border-radius: 5px;
  float: right;
  height: 40px;
}

@media screen and (max-width: 600px) {
  .boton-enviar-accion-detalle {
    position: absolute;
    top: 100px;
    position: absolute;
    top: 100px;
    right: 25px;
    width: 36px;
    height: 36px;
    padding: 0;
    border-radius: 18px;
  }

  .boton-enviar-accion-detalle p {
    display: none;
  }
}
