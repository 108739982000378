:root {
  --gray100: #f9f9fb;
  --gray300: #eaedf3;
}

.navbar-w {
  /* position: fixed; */
  width: 100%;
  height: 72px;
  top: 0;
  left: 0;
  padding: 0;
  z-index: 300;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar-logo {
  width: 238px !important;
}
.navbar-logo div {
  height: 100%;
}
.logo {
  margin: 0 20px;
}
.btn-menu {
  line-height: 51px;
  margin: 0 0 0 20px;
}

.navbar-actions {
  padding: 0;
  box-sizing: border-box;
}
.navbar-actions div {
  height: 100%;
  padding: 0;
}
.navbar-actions .navbar-list {
  margin-bottom: 0;
  margin-top: 0;
}
.navbar-list li {
  margin: 0 25px 0 0;
  height: auto;
}

.navbar-search {
  height: 100%;
  width: inherit;
  padding: 0 28px;
  box-sizing: border-box;
  background: none;
  border: none;
}

.navbar-search svg {
  color: #0f69b4;
}

.navbar-search-div {
  height: 100%;
  width: inherit;
  background: none;
  border: none;
}
.navbar-search-div div {
  height: 100%;
  margin-right: 10px;
  box-sizing: border-box;
}

.navbar-badge {
  top: 0.5em !important;
  right: -0.5em !important;
}

.MuiBadge-colorSecondary {
  background-color: #3AD377 !important;;
}

#input-searchbar-header {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #0f69b4;
  background: none;
  border: none;
  width: inherit;
}

#input-searchbar-header::placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.03em;
  color: #0f69b4;
  opacity: 0.5;
}

#input-searchbar-header:focus {
  outline: none;
}

.navbar-opciones-perfil {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.navbar-botton-group {
  padding: 0 !important;
  float: right !important;
  display: inline-flex;
}
.navbar-button {
  width: 50px;
  height: 50px !important;
  border-radius: 50%;
  background-color: #f9fafc;
  margin: 0.5em 1em !important;
}

.navbar-button button {
  min-width: auto;
}

.navbar-button button:hover {
  min-width: auto;
  background-color: transparent;
}

.navbar-button a .icon {
  height: 100%;
  width: 100%;
  color: #0f69b4;
}

.navbar-info {
  padding: 10px !important;
  right: 70px;
  min-width: 160px;
  cursor: pointer;
}
.navbar-info div {
  height: 2.5rem;
}
.navbar-info-name {
  margin: 0 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: -0.03em;
  color: #000000;
}

.navbar-profile {
  padding: 10px 20px 10px 0 !important;
  cursor: pointer;
}
.navbar-profile-picture {
  width: 48px;
  height: 48px;

}

.navbar-mobile {
  position: fixed;
  width: 100%;
  height: 60px;
  bottom: 0;
  right: 0;
  padding: 10px;
  box-sizing: border-box;
  z-index: 300;
  border-top: 1px solid var(--gray300);
  background: white;
  display: none;
}
.navbar-mobile-list {
  width: 100%;
  height: 100%;
}

.navbar-mobile-list li {
  width: 25%;
}

.boton-notificaciones {
  width: 100%;
  height: 100%;
}

.boton-notificaciones:focus {
  outline: none;
}

.boton-notificaciones span {
  width: auto;
  height: auto;
}

.div-politicas-privacidad {
  max-height: 60vh;
  overflow-y: scroll;
  margin-top: 10px;
  background: #ffffff;
  border: 1px solid #1387da;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px;
}

#mini-panel-usuario {
  width: 285px;
  height: 307px;
  background: #feffff;
  border: 1px solid #f9fbfc;
  box-sizing: border-box;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  position: absolute;
  top: 72px;
  right: 0px;
  z-index: 4;
  padding: 14px;
}

#mini-panel-usuario p {
  margin-bottom: 0;
}

.mini-panel-div-date-hour {
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  margin-bottom: 16px;
}

.mini-panel-div-picture-name-location {
  display: flex;
}

.minipanel-div-correo-run-id div {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.minipanel-div-correo-run-id div svg {
  color: #2475b9;
  width: 20px;
  margin-right: 8px;
}

.minipanel-div-logout-settings {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 45px;
}

.mini-panel-date {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #1c1861;
}

.mini-panel-hour {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #1c1861;
}

.mini-panel-name {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #001c41;
  margin-left: 13px;
}

.mini-panel-location {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #a6acb3;
  margin-left: 13px;

}

.minipanel-div-correo-run-id p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #001c41;
}

.minipanel-div-logout-settings p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #001c41;
  cursor: pointer;
}

.minipanel-div-logout-settings p:hover {
  color: #2475B9;
}

.minipanel-div-logout-settings svg {
  color: #2475B9;
  width: 16px;
  transform: rotate(0.5turn);
}

.minipanel-linea-gris-logout-settings{
  width: 1px;
  height: 19px;
  background: #969696;
}
.botones-minipanel-usuario {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 50%;
}

@media (max-width: 960px) {
  .btn-menu {
    display: block;
  }
  .navbar-actions,
  .navbar-search,
  .navbar-info {
    display: none;
  }
  .navbar-mobile {
    display: block;
  }
}

@media (min-width: 959px) {
  .btn-menu {
    display: none;
  }
  .navbar-w {
    display: flex;
  }
}

@media (width: 1024px) {
  .navbar-actions,
  .navbar-search,
  .navbar-info {
    display: none;
  }
  .navbar-mobile {
    display: block;
  }
}
