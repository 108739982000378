/* .row {
   height: 100vh;
 } */

.img-header {
  display: block;
  margin-left: auto;
  width: 80%;
}

.error-label {
  color: #e4434a;
  font-size: 0.9em;
  margin-top: 2em;
}

.login-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 78px;
  margin-bottom: 138px;
  width: 100%;
  height: 96px;
}
.title-header h5 {
  color: #fff;
}

.header-registro {
  background: #011d41;
}

.login__logo {
  height: 96px;
  left: 10%;
  width: 100%;
}

.login__bg {
  background-image: url(../../assets/login/login_bg.png);
  background-size: cover;
  min-height: fit-content;
  width: 105%;
}

.login-description {
  color: #fff;
  display: flex;
  justify-content: center;
  width: 100%;
}

.description-wrapper {
  width: 80%;
}

.description__title {
  color: #fff;
  font-size: 32px;
  font-weight: bold;
}

.description__content {
  height: auto;
  line-height: 38px;
}

.login__title {
  color: #1069b4;
  font-weight: 600;
}

.login {
  align-items: left;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 2em 2em 2em 2em;
  border-radius: 0;
  border: none;
  -webkit-box-shadow: 5px 10px 8px 1px rgba(245, 246, 248, 1);
  -moz-box-shadow: 5px 10px 8px 1px rgba(245, 246, 248, 1);
  box-shadow: 5px 10px 8px 1px rgba(245, 246, 248, 1);
}

.submit-login {
  background-color: #1069b4 !important;
  border: none;
  margin-top: 20px;
}

.login button.btn.btn-primary,
.login button.btn.btn-primary:active {
  width: 100%;
  background-color: #e22c2c;
  border-color: #e22c2c;
}

.login .form-control.is-valid,
.login .was-validated .form-control:valid {
  border-color: #ced4da;
}

.login .form-control.is-valid::after {
  display: none;
}
