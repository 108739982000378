.table{
    background-color: white;
}

.breadcrumb-section {
    margin: 1.5em 0em;
}

.container-information{
    margin-right: 3em !important;
    margin-left: 2.5em !important;
}

.nav-tabs .nav-item{
    width: 10%;
}

.nav-tabs span{
    color: #A6ACB3;
}

.nav-tabs .active span{
    color: #0F69B4;
}

@media(max-width: 700px){
    .container-information{
        margin-right: 0em !important;
        margin-left: 0em !important;
    }
    .nav-tabs .nav-item{
        width: 50%;
    }
}