.input-crear-proyecto {
  background: #ffffff;
  border: 1px solid #1387da;
  box-sizing: border-box;
  border-radius: 5px;
}

textarea.input-crear-proyecto {
  width: 100%;
}

textarea.input-crear-proyecto:focus {
  outline: none;
}

#boton-subir-imagen-proyecto {
  background: #d7ebfb;
  color: #0f69b4;
  border-radius: 5px;
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
}

#boton-subir-imagen-proyecto p {
  margin-bottom: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
}

#boton-subir-imagen-proyecto svg {
  width: 12px;
}

#subir-imagen-proyecto {
  display: none;
}

#div-etiquetas-crear-proyecto {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
}

#div-solo-etiquetas-crear-proyecto {
  display: flex;
  align-items: center;
  height: 100%;
}

#input-etiquetas-crear-proyecto {
  background: none;
  border: none;
}

.etiquetas-proyecto {
  height: 28px;
  background: #d7ebfb;
  border-radius: 14px;
  padding: 0 10px;
  margin-bottom: 0;
  margin-left: 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #0f69b4;
}

#seccion-botones-nuevo-proyecto {
  display: flex;
  justify-content: flex-end;
  padding: 0 24px 24px 24px;
}

#seccion-botones-nuevo-proyecto button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 107px;
  height: 36px;
  border-radius: 5px;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.03em;
}

#seccion-botones-nuevo-proyecto button:focus {
  outline: none;
}

#boton-guardar-crear-proyecto {
  background: #1387da;
  border-radius: 5px;
  margin-left: 16px;
  color: #ffffff;
}

#boton-cancelar-crear-proyecto {
  background: #dbeaf7;
  color: #0f69b4;
  margin-left: 16px;
}

.div-titulo-anclar-proyecto {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

@media screen and (max-width: 680px){
  .div-titulo-anclar-proyecto {
    display: block;
  }
}

.div-anclar-proyecto {
  display: flex;
  align-items: center;
}

.div-anclar-proyecto label {
  margin: 0 10px;
}

/* Preview */

.fullCard-styles {
  margin-bottom: 1.2em;
  width: 1120px;
}

@media screen and (max-width: 1120px) {
  .fullCard-styles {
    width: 100%;
  }
  .modal-preview-proyecto {
    width: 100%;
  }
}

.fullImage {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.title-link {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;
}

.title-link h4 {
  margin-bottom: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #ffffff;
  max-height: 144px;
  overflow-y: scroll;
}

.title-link h4::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

.div-icono-link {
  margin-left: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  background: rgba(249, 250, 252, 0.2);
  cursor: pointer;
  transition: all 0.2s;
}

.div-icono-link:hover {
  background: rgba(249, 250, 252, 0.5);
}

.div-tags-fecha-area-proyecto {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
}

.div-tags-fecha-area-proyecto p {
  margin-bottom: 0;
  margin-right: 19px;
}

.div-tags-fecha-area-proyecto img {
  margin-right: 10px;
}

.ver-mas-desplegar-texto {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #2475b9;
  background: #d7ebfb;
  padding: 0 8px;
  border-radius: 10px;
  cursor: pointer;
}
.badge-style {
  background-color: #f0f3ff;
  color: #2475b9;
  margin-right: 1em;
  padding: 0.5em 1em;
  font-size: 0.85em;
}

.ver-mas-desplegar-texto:hover {
  text-decoration-line: underline;
}

#texto-descriptivo-tarjeta-proyecto {
  overflow-y: auto;
  max-height: 250px;
}

.modal-preview-proyecto {
  background-color: #ffffff;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.boton-cerrar-preview-proyecto {
  height: 36px;
  width: 130px;
  background: #d7ebfb;
  border-radius: 5px;
  color: #2475b9;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.03em;
  border: none;
  display: flex;
  justify-content: center;
}

.boton-cerrar-preview-proyecto:focus {
  outline: none;
}
