#searchbar-agrupacion {
  background: #feffff;
  border: 1px solid #f9fafc;
  box-sizing: border-box;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.03);
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 14px;
  margin-bottom: 16px;
}

#input-searchbar-agrupacion {
  border: none;
  background: none;
}

#search-icon-agrupacion {
  color: #a6acb3;
  width: 13px;
}

#plus-icon-agrupacion {
  color: #0f69b4;
  width: 14px;
}

#div-busquedas-agrupacion {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 12px;
}

#div-busquedas-agrupacion h3 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #0f69b4;
  margin-right: 4px;
}

.etiquetas-busqueda-agrupacion {
  height: 28px;
  background: #d7ebfb;
  color: #325897;
  border-radius: 14px;
  padding: 0 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 4px;
}

.etiquetas-busqueda-agrupacion p {
  margin-bottom: 0;
}

.etiquetas-busqueda-agrupacion svg {
  width: 16px;
}
