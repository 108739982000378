.ball_wrap {
  position: relative;
  margin: 150px;
  width: 90px;
}

.linea-gris-logo {
  width: 2px;
  height: 48px;
  background: #eeeeee;
}
