/* text colors */

:root {
  --title-color: #1d1960;
  --description-color: #8987ad;
  --box-text-color: #818181;
  --active-person-color: #d5d5f9;
  --chat-person-color: #474747;
  --chat-main-color: #2475b9;
  --time-color: #2475b9;

  /* background colors */

  --list-section-color: #f5f6fc;
  --person-section-color: #c5c4f7;
  --searchForm-section-color: #dbeaf7;
}

#div-conversacion {
  display: flex;
  margin-top: 72px;
}

#sidebar-info-ciudadano-conversacion {
  width: 272px;
  height: calc(100vh - 72px);
  padding: 0 32px 32px 0;
  background: #f9fafc;
}

#div-searchbar-coversacion {
  width: 100%;
  height: 91px;
  padding-bottom: 16px;
  display: flex;
  align-items: flex-end;
}

#input-buscar-en-chat {
  width: 100%;
  background: #feffff;
  border: 1px solid #f9fafc;
  box-sizing: border-box;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.03);
  border-radius: 2px;
}

#div-info-ciudadano-conversacion {
  background: #feffff;
  box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.03);
  border-radius: 2px;
  height: 80%;
  padding: 24px;
}

#div-info-ciudadano-conversacion p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #000000;
}

#ul-info-ciudadano-conversacion {
  list-style: none;
  padding: 0;
}

#ul-info-ciudadano-conversacion li {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #49505d;
  margin: 8px 0;
}
#ul-info-ciudadano-conversacion li img {
  margin-right: 8px;
}

#dropdown-encuestas-ciudadano-conversacion {
  background: #dbeaf7;
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  letter-spacing: 0.03em;
  color: #0f69b4;
  height: 36px;
  width: 100%;
  border: none;
}

#dropdown-encuestas-ciudadano-conversacion img {
  margin-right: 12px;
}

#dropdown-encuestas-ciudadano-conversacion::after {
  display: none;
}

#link-regresar-conversaciones {
  display: flex;
  align-items: center;
  width: fit-content;
}

#link-regresar-conversaciones p {
  margin: 0 0 0 8px;
}

#dropdown-estatus-conversacion {
  justify-content: space-between;
  width: 192px;
  height: 40px;
  background: #ffffff;
  color: #000000;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
}

#dropdown-estatus-conversacion::after {
  display: none;
}

.boton-info-chat {
  display: none;
}

.mis-mensajes-chat,
.otros-mensajes-chat {
  max-width: 100%;
  width: fit-content;
}

.mis-mensajes-chat p,
.otros-mensajes-chat p {
  margin-bottom: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: left;
  white-space: pre-line;
}

.mis-mensajes-chat {
  color: #feffff;
  background: #325897;
  border: 1px solid #1387da;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 16px;
  display: flex;
  justify-content: flex-end;
  text-align: right;
}

.otros-mensajes-chat {
  color: #325897;
  background: #feffff;
  border: 1px solid #1387da;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 16px;
  text-align: left;
}

.div-mis-mensajes-chat {
  display: flex;
  flex-flow: column;
  align-items: flex-end;
}

.div-mis-mensajes-chat,
.div-otros-mensajes-chat {
  width: fit-content;
  min-width: 40%;
  max-width: 60%;
}

.div-nombre-chat {
  margin: 8px;
  display: flex;
}

.quill-chat:focus {
  outline: none;
}

@media screen and (max-width: 700px) {
  #sidebar-info-ciudadano-conversacion {
    position: fixed;
    right: -70%;
    transition: all 0.5s;
    height: calc(100vh - 72px);
    width: 70%;
  }

  #div-searchbar-coversacion {
    display: none;
  }

  #sidebar-info-ciudadano-conversacion {
    padding: 0;
  }

  .boton-info-chat {
    min-width: 36px;
    height: 36px;
    border-radius: 18px;
    background: #ffffff;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #dropdown-estatus-conversacion {
    width: auto;
  }

  #dropdown-estatus-conversacion img {
    display: none;
  }

  #div-conversacion .ql-bold,
  #div-conversacion .ql-italic,
  #div-conversacion .ql-underline {
    display: none;
  }

  #div-conversacion .ql-editor {
    height: 100%;
    width: 100%;
  }

  #div-conversacion .ql-container {
    height: 100%;
    width: 100%;
  }

  #div-conversacion .quill-chat {
    padding: 0;
  }

  #div-conversacion .boton-chat-enviar {
    font-size: 0;
    min-width: 36px;
    width: 36px;
    height: 36px;
    padding: 0;
    margin: 0;
    border-radius: 18px;
  }

  #div-conversacion .MuiButton-endIcon {
    margin: 0;
  }
}
