.container-404{
    height: 90vh;
}
.row-404{
    height: 90%;
    text-align: center;
    align-content: center;
}
.col-center{
    align-self: center;
}
.boton-404{
    background: #0f69b4;
    border-radius: 5px;
    height: 40px;
    width: auto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: inline-flex;
    align-items: center;
    letter-spacing: 0.03em;
    color: #feffff;
    justify-content: space-evenly;
    align-items: center;
  }

  .boton-404:hover{
    background: #0f69b4;
  }

  .boton-404 p {
    margin-bottom: 0;
    margin-right: 40px;
  }

  
  