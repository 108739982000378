.opciones-verificar-aceptar-rechazar-validaciones {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.opciones-verificar-aceptar-rechazar-validaciones p {
  margin-bottom: 0;
}

.opciones-verificar-aceptar-rechazar-validaciones svg {
  width: 13px;
}

.texto-abrir-chat-ciudadanos {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: #0f69b4;
  cursor: pointer;
  margin-bottom: 0;
}

.texto-abrir-chat-ciudadanos p {
  margin-bottom: 0;
}

.texto-abrir-chat-ciudadanos:hover {
    text-decoration-line: underline;
}

.texto-abrir-chat-ciudadanos:active {
    color: #2fa2ff;
}
