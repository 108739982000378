#contenedor-modal-nueva-pregunta {
  background: #f9fafc;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  width: 680px;
  min-height: fit-content;
}

#contenedor-modal-nueva-pregunta:focus {
  outline: none;
}

#seccion-datos-nueva-pregunta {
  padding: 32px;
}

#seccion-datos-nueva-pregunta {
  min-height: 356px;
}

#titulo-nueva-pregunta {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #0f69b4;
  margin-bottom: 0;
}

.labels-crear-nueva-pregunta {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #0f69b4;
  margin-top: 32px;
  margin-bottom: 0;
}

#dropdown-crear-pregunta::after {
  display: none;
}

#seccion-botones-nueva-pregunta {
  display: flex;
  justify-content: flex-end;
  padding: 0 24px 24px 24px;
}

#seccion-botones-nueva-pregunta button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 107px;
  height: 36px;
  border-radius: 5px;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.03em;
}

#seccion-botones-nueva-pregunta button:focus {
  outline: none;
}

#boton-guardar-crear-pregunta {
  background: #1387da;
  border-radius: 5px;
  margin-left: 16px;
  color: #ffffff;
}

#boton-cancelar-crear-pregunta {
  background: #dbeaf7;
  color: #0f69b4;
}

#input-pregunta-abierta {
  max-width: 520px;
  max-height: 40px;
}

#dropdown-crear-pregunta {
  display: flex;
  width: 200px;
  height: 36px;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #d5deff;
  box-sizing: border-box;
  border-radius: 5px;
  color: #000000;
  margin-top: 24px;
  padding: 0 0 0 10px;
}

#dropdown-crear-pregunta div {
  display: flex;
  align-items: center;
}

#dropdown-crear-pregunta div p {
  margin-bottom: 0;
}

#dropdown-crear-pregunta svg {
  color: #0f69b4;
  width: 20px;
  margin-right: 10px;
}

.div-opciones-mutiples-respuestas {
  display: flex;
  justify-content: flex-start;
  max-width: 240px;
  max-height: 40px;
  align-items: center;
  margin: 3px 0;
}

.input-opciones-multiples-respuestas {
  max-width: 240px;
  max-height: 40px;
  background: #ffffff;
  border: 1px solid #1387da;
  box-sizing: border-box;
  border-radius: 5px;
}

.div-opciones-mutiples-respuestas svg {
  color: #1387da;
  margin: 0 8px;
}

#bloque-de-respuestas {
  max-height: 35vh;
  overflow-y: scroll;
}

/* --------------- Responsive ---------------- */

@media screen and (max-width: 680px) {
  #contenedor-modal-nueva-pregunta {
    width: 90%;
  }

  #contenedor-modal-nueva-pregunta .row {
    flex-flow: column;
  }
}
