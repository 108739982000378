.dropdown-agregar-administrador {
  width: 100%;
}

.input-agregar-administrador,
.dropdown-agregar-administrador button {
  background: #ffffff;
  border: 1px solid #1387da;
  box-sizing: border-box;
  border-radius: 5px;
  height: 40px;
}

.dropdown-agregar-administrador button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.dropdown-agregar-administrador button:hover,
.dropdown-agregar-administrador button:focus,
.dropdown-agregar-administrador button:active {
  background: #ffffff !important;
  border: 1px solid #1387da !important;
}

.dropdown-agregar-administrador button p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #7f8792;
  margin-bottom: 0;
}

.dropdown-agregar-administrador button svg {
  color: #0f69b4;
}

.dropdown-agregar-administrador button::after {
  display: none;
}

.p-password-no-coincide-crear-administrador {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: red;
  text-decoration-line: underline;
  margin-bottom: 0;
  position: absolute;
}

.dropdown-menu-area-agregar-admin {
  overflow-y: scroll;
  max-height: 100px;
}

@media screen and (max-width: 600px) {
  .dropdown-menu-area-agregar-admin {
    overflow-y: scroll;
    max-height: 100px;
    max-width: 100%;
    max-height: 60vh;
  }

  .dropdown-menu-area-agregar-admin .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: center;
    background-color: transparent;
    border: 0;
    min-height: fit-content;
    white-space: normal;
  }
}
