/* ---------------- Estadisticas --------------- */
.div-texto-preguta-encuesta {
  padding: 0;
  height: 40px;
}

.div-pregunta-encuesta {
  background: #ffffff;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.03);
  border-radius: 2px 2px 2px 0px;
  padding: 16px 32px;
  margin-bottom: 8px;
}

.texto-pregunta-encuestas {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #49505d;
  margin-bottom: 0;
}

.div-etiqueta-azul-oscuro-respuesta-encuestas,
.div-etiqueta-azul-claro-respuesta-encuestas,
.div-etiqueta-respuesta-encuestas {
  display: flex;
  align-items: center;
  padding: 0 12px;
  height: 40px;
}

.div-etiqueta-azul-oscuro-respuesta-encuestas {
  background: #1387da;
  border: 1px solid #f9fafc;
  box-sizing: border-box;
}

.div-etiqueta-azul-claro-respuesta-encuestas {
  background: #d0e7f8;
  border: 1px solid #feffff;
  box-sizing: border-box;
}

.etiqueta-respuesta-encuestas,
.valor-respuestas-encuestas {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.etiqueta-respuesta-encuestas {
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 0em;
}

.valor-respuestas-encuestas {
  color: #4d4d4d;
}

.div-info-usuario-hora-respuestas-pregunta-votacion {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  margin-right: 8px;
  background: #feffff;
  border: 1px solid #f9fafc;
  box-sizing: border-box;
  border-radius: 2px;
}

.div-info-usuario-hora-respuestas-pregunta-votacion svg {
  width: 16px;
  margin: 0 10px;
  color: #0f69b4;
}

.div-info-usuario-hora-respuestas-pregunta-votacion p {
  margin-bottom: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #4d4d4d;
  margin-right: 10px;
}

.leyenda-estadisticas {
  background: #ffffff;
  box-shadow: 2px 2px 6px rgb(0 0 0 / 3%);
  border-radius: 2px 2px 2px 0px;
  padding: 16px 32px;
  margin-bottom: 8px;
  width: 100%;
  text-align: center;
}

/* -------------- Responsive -------------- */

@media screen and (max-width: 600px) {
  .div-pregunta-encuesta {
    margin-top: 50px;
  }
}
