.footer {
  z-index: 1201;
  background-color: #011c41;
  color: #ffffff;
  text-align: "center";
  position: "absolute";
  bottom: 0;
  width: "100%";
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}
.footer p {
  margin: 0 10px;
  text-align: center;
}
.footer a {
  text-align: center;
  color: #feffff;
}
.footer img {
  margin: 10px 5px;
}

.footer span {
  font-weight: 600;
  cursor: pointer;
}

.footer span:hover {
  text-decoration-line: underline;
}

.contenedor-modales-footer {
  background: #f9fafc;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  width: 80%;
  max-height: 90vh;
  padding: 20px;
}

.contenedor-modales-footer:focus {
  outline: none;
}

.contenedor-modales-footer textarea{
  background: none;
  border: none;
  width: 100%;
}

.titulo-modales-footer {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #2475b9;
}

.contenedor-modales-footer button {
  background: #1387da;
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.03em;
  color: #feffff;
  float: right;
  width: 120px;
  height: 36px;
  border: none;
}

.div-video-footer {
  height: calc(90vh - 110px);
  margin-bottom: 6px;
}

@media screen and (max-width: 700px) {
  .footer {
    flex-flow: column;
  }
}
