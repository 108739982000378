#container-perfil-ajustes {
  background: #ffffff;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
  width: 100%;
  padding: 32px;
  margin-bottom: 48px;
  min-height: calc(100vh - 245px);
}

.titulos-seccion-ajustes {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #0f69b4;
  margin-bottom: 16px;
}

.labels-ajustes {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #0f69b4;
  margin-bottom: 0;
}

.input-ajustes {
  background: #f9fafc;
  border: 1px solid #1387da;
  box-sizing: border-box;
  border-radius: 5px;
}

#input-numero-votos-ajustes {
  width: 100%;
  padding: 0 10px;
}

.input-oscuro-ajustes {
  background: #d7ebfb;
  border: 1px solid #1387da;
  box-sizing: border-box;
  border-radius: 5px;
}

.input-ajustes,
.input-oscuro-ajustes,
#dropdown-organizacion-integraciones-ajustes {
  height: 40px;
  color: #0f69b4;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17;
}

.botones-ajustes {
  background: #0f69b4;
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.03em;
  color: #feffff;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
  min-width: 123px;
}

.botones-ajustes svg {
  width: 16px;
  margin-left: 12px;
}

#div-avatares {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.row-ajustes-salto {
  margin-bottom: 38px;
}

.col-boton-enviar-ajustes .col {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
  margin-top: 8px;
  height: 40px;
}

.col-botones-tipo-integraciones-ajustes {
  padding: 0;
  display: flex;
  justify-items: flex-start;
  align-items: flex-end;
}

#dropdown-organizacion-integraciones-ajustes {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f9fafc;
  border: 1px solid #1387da;
  box-sizing: border-box;
  border-radius: 5px;
}

#dropdown-organizacion-integraciones-ajustes p {
  margin-bottom: 0;
}

#dropdown-organizacion-integraciones-ajustes::after {
  display: none;
}

.p-password-no-coincide-ajustes {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: red;
  text-decoration-line: underline;
  margin-bottom: 0;
  position: absolute;
}

.aviso-privacidad-textarea {
  width: 100%;
  background: #ffffff;
  border: 1px solid #1387da;
  box-sizing: border-box;
  border-radius: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #49505d;
}

.aviso-privacidad-textarea:focus {
  outline: none;
}

.col-boton-guardar-aviso-privacidad {
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 600px) {

  .titulos-seccion-ajustes {
    margin-top: 60px;
  }

  .col-boton-guardar-numvotos {
    margin-top: 5px;
    justify-content: flex-end;
    padding: 0px 15px;
  }

}

.div-avatares-configuracion {
  display: flex;
  align-items: flex-start;
  padding-bottom: 25px;
  border-bottom: 1px solid #f0f3ff;
  margin-bottom: 24px;
}
.div-div-avatares-configuracion {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.icono-avatar-configuracion,
.icono-avatar-seleccionado-configuracion {
  box-sizing: content-box;
  max-height: 46px;
  max-width: 46px;
  border-radius: 100%;
  margin: 10px;
  padding: 1px;
}

.icono-avatar-configuracion:hover,
.icono-avatar-seleccionado-configuracion {
  border: #3187ce 6px solid;
  transition: all 0.2s;
  margin: 4px;
  
}
