.dropdown-local-menu {
  height: 15em;
  overflow-y: scroll;
}

.cargando-editar-votacion {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  height: 340px;
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #0f69b4;
}

.icono-rotate-modal-votacion {
  animation: rotation 1s infinite linear;
}



@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
