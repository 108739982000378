
.popover-basic{
    max-width: 400px;
    width: 400px;
    height: 400px;
}

#popContain{
    right: 25%;
    position: absolute;
    float: right;
}

.notificationRow{
    margin: 1em 0em;
}

.iconNotification{
    color: #2167a0;
    width: 100% !important;
    height: 1.5em;
}

@media(max-width:1440px){
    #popContain{
        right: 35%;
    }   
    .popover-basic{
        max-width: 400px;
        width: 400px;
    }
}

@media(max-width:700px){
    #popContain{
        right: 35%;
    }   
    .popover-basic{
        
        max-width: 600px;
        width: 300px;
    }
}

