.table-action-table th {
  border: 1px solid #f9fafc;
}

.table-action-table thead th {
  border: 1px solid #f9fafc;
}

.table-action-table td {
  border: 1px solid #f9fafc;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #4d4d4d;
}

.table-header {
  background: #f9fafc;
  border: 1px solid #f9fafc;
  box-sizing: border-box;
}

.table-header th {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #000000;
}

.table-action-table .form-check {
  display: flex;
  align-items: center;
}

.div-empty-action-table {
  background: #f9fafc;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 270px);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #011c41;
}

.strongBlue {
  background: #5850ec;
  height: 1em;
  width: 1em;
  border-radius: 50%;
  margin-left: 1em;
  margin-right: 0.5em;
  position: relative;
  display: inline-flex;
}
.blue {
  background: #2e5bff;
  height: 1em;
  width: 1em;
  border-radius: 50%;
  margin-left: 1em;
  margin-right: 0.5em;
  position: relative;
  display: inline-flex;
}
.lightBlue {
  background: #d5deff;
  height: 1em;
  width: 1em;
  border-radius: 50%;
  margin-left: 1em;
  margin-right: 0.5em;
  position: relative;
  display: inline-flex;
}
.green {
  background: #3bd278;
  height: 1em;
  width: 1em;
  border-radius: 50%;
  margin-left: 1em;
  margin-right: 0.5em;
  position: relative;
  display: inline-flex;
}
.yellow {
  background: #f7c137;
  height: 1em;
  width: 1em;
  border-radius: 50%;
  margin-left: 1em;
  margin-right: 0.5em;
  position: relative;
  display: inline-flex;
}

.red {
  background: #f42449;
  height: 1em;
  width: 1em;
  border-radius: 50%;
  margin-left: 1em;
  margin-right: 0.5em;
  position: relative;
  display: inline-flex;
}
.gray {
  background: #a6acb3;
  height: 1em;
  width: 1em;
  border-radius: 50%;
  margin-left: 1em;
  margin-right: 0.5em;
  position: relative;
  display: inline-flex;
}
.darkgray {
  background: #49505d;
  height: 1em;
  width: 1em;
  border-radius: 50%;
  margin-left: 1em;
  margin-right: 0.5em;
  position: relative;
  display: inline-flex;
}
