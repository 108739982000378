#form-calendario-inline {
  display: flex;
  background: #ffffff;
  width: inherit;
  height: 40px;
  border: 1px solid #1387da;
  box-sizing: border-box;
  border-radius: 5px;
}

#form-calendario-inline div {
  flex-wrap: nowrap;
  align-items: center;
  padding-right: 3px;
  width: 100%;
  height: 100%;
  padding-left: 3px;
}

#form-calendario-inline div .input-group {
 height: 100%;
}



#fecha-calendario-inline {
  height: 100%;
  padding: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

#form-calendario-inline div div::before,
#form-calendario-inline div div::after {
  display: none;
}

#fecha-calendario-inline::after {
  display: none;
}

.rangecontainer {
  display: none;
}

.timeContainer {
  display: none;
}

.input-group > .custom-select:not(:first-child),
.input-group > .form-control:not(:first-child) {
  display: none;
}

div#daterangepicker.daterangepicker {
  top: -160px !important;
}

 

#input-calendario-inline, #DateRangePickerChildren, .daterangepickercontainer {
  width: 100%;
  height: 100%;
  background: none;
  border: none;
}
